import classNames from "classnames";
import Container from "pages/_components/Container";
import GridTable from "pages/_components/GridTable/GridTable";
import I18n from "pages/_components/I18n";
import MovementItem from "pages/_components/MovementItem";
import ProductList from "pages/_components/product/List";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { replace } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as depositsActions, selectors as depositsSelectors } from "reducers/deposits";
import { selectors as loginSelectors } from "reducers/login";
import * as configUtil from "util/config";

class MovementsTable extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        filters: shape({}).isRequired,
        firstFetched: bool.isRequired,
        fullList: bool.isRequired,
        isDesktop: bool.isRequired,
        isFetchingMovements: bool.isRequired,
        isTablet: bool.isRequired,
        moreMovements: bool.isRequired,
        movements: arrayOf(shape({})).isRequired,
        pageNumber: number.isRequired,
        operationType: string.isRequired,
        operationId: string.isRequired,
        operationCurrency: number.isRequired,
        totalCountOnline: number,
        offset: number,
        history: shape({
            push: func.isRequired,
        }).isRequired,
        helpMessage: string,
    };

    static defaultProps = {
        totalCountOnline: 0,
        offset: 0,
        helpMessage: "",
    };

    fetchNeeded = () => {
        const { movements, pageNumber } = this.props;
        const movementsFirstPage = configUtil.getInteger("movementsFirstPage");
        const movementsPerPage = configUtil.getInteger("movementsPerPage");

        const max = movementsFirstPage + pageNumber * movementsPerPage;
        return movements.length < max;
    };

    fetchMoreMovements = () => {
        const { operationId, operationType, dispatch, fullList } = this.props;
        let { filters } = this.props;
        const { pageNumber, offset, totalCountOnline } = this.props;

        filters = { ...filters, pageNumber: pageNumber + 1, fullList };

        if (operationType === "accounts") {
            // chequeo si tengo que ir a hacer fetch o no
            if (this.fetchNeeded()) {
                const movementsPerPage = configUtil.getInteger("movementsPerPage");
                const movementsFirstPage = configUtil.getInteger("movementsFirstPage");

                const totalOnlineInPage =
                    totalCountOnline > movementsFirstPage
                        ? (totalCountOnline - movementsFirstPage) % movementsPerPage
                        : totalCountOnline;

                const missing = offset === 0 ? movementsPerPage - totalOnlineInPage : movementsPerPage;

                filters = { ...filters, offset: offset + 1, quantity: missing };

                dispatch(accountsActions.fetchMoreMovements(operationId, filters));
            } else {
                dispatch(accountsActions.noFetchMovements(operationId, pageNumber + 1, true));
            }
        }

        if (operationType === "deposits") {
            dispatch(depositsActions.fetchMoreMovements(operationId, filters));
        }
    };

    handleClick = () => {
        this.fetchMoreMovements();
    };

    handleFiletrClick = () => {
        const { dispatch, operationId, operationType } = this.props;
        dispatch(replace(`/${operationType}/${operationId}/filters`));
    };

    handleMovementClick = (movement) => {
        const { dispatch, operationType } = this.props;

        switch (operationType) {
            case "accounts":
                dispatch(accountsActions.setSelectedMovement(movement));
                break;
            case "deposits":
                dispatch(depositsActions.setSelectedMovement(movement));
                break;
            default:
                dispatch();
                break;
        }
    };

    columnsTypes = (operationType, isDesktop, isTablet) => {
        let operation = "";

        if (operationType === "accounts") {
            operation = isDesktop
                ? `minmax(auto, 4rem) ${isTablet ? "repeat(5, 1fr) 4rem" : "repeat(6, 1fr) minmax(4rem, 6rem)"}`
                : "1fr minmax(4rem, auto) 1.75rem";
        } else if (operationType === "deposits") {
            operation = isDesktop ? "repeat(5, 1fr)" : "1fr auto 1.75rem";
        }
        return operation;
    };

    renderItem = (movement) => {
        const { operationCurrency, operationType, isDesktop, isTablet, history } = this.props;
        return (
            <GridTable.Container
                className={classNames("product-data", { "gapX-3": isDesktop })}
                columnsTemplate={this.columnsTypes(operationType, isDesktop, isTablet)}
                key={movement.idStatement}
                onClick={() => {
                    history.push(`/${operationType}/${movement.idAccount}/movement`);
                    this.handleMovementClick(movement);
                }}
                rows={isDesktop ? 1 : 2}>
                <MovementItem
                    isDesktop={isDesktop}
                    isTablet={isTablet}
                    movement={movement}
                    operationCurrency={operationCurrency}
                    operationType={operationType}
                />
            </GridTable.Container>
        );
    };

    renderHeaderList = (operationType) => {
        const { isDesktop, isTablet } = this.props;
        if (operationType === "accounts") {
            return (
                <GridTable.Container
                    className={classNames({ "gapX-3": isDesktop })}
                    columnsTemplate={this.columnsTypes(operationType, isDesktop, isTablet)}>
                    {isDesktop && (
                        <>
                            <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                <I18n id="tableHeader.channel" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-start" inHeader>
                                <I18n id="tableHeader.date" />
                            </GridTable.Data>
                        </>
                    )}
                    <GridTable.Data
                        columnStart={isDesktop ? 3 : 1}
                        columnWidth={isTablet ? 2 : 1}
                        alignX="flex-start"
                        inHeader>
                        <I18n id="tableHeader.description" />
                    </GridTable.Data>
                    <GridTable.Data columnStart={isDesktop ? `${isTablet ? 5 : 6}` : 2} alignX="flex-end" inHeader>
                        <I18n id="tableHeader.amount" />
                    </GridTable.Data>
                    {isDesktop && (
                        <>
                            <GridTable.Data columnStart={isTablet ? 6 : 7} alignX="flex-end" inHeader>
                                <I18n id="tableHeader.balance" />
                            </GridTable.Data>
                        </>
                    )}
                    <GridTable.Data
                        tabIndex="-1"
                        columnStart={isDesktop ? `${isTablet ? 7 : 8}` : 3}
                        alignX="flex-end"
                        inHeader
                    />
                </GridTable.Container>
            );
        }
        if (operationType === "deposits") {
            return (
                <GridTable.Container columnsTemplate={this.columnsTypes(operationType, isDesktop, isTablet)}>
                    {isDesktop && (
                        <>
                            <GridTable.Data columnStart={1} alignX="flex-start">
                                <I18n id="tableHeader.date" />
                            </GridTable.Data>
                        </>
                    )}
                    <GridTable.Data columnStart={isDesktop ? 2 : 1} columnWidth={2} alignX="flex-start">
                        <I18n id="tableHeader.description" />
                    </GridTable.Data>
                    <GridTable.Data columnStart={isDesktop ? 4 : 3} alignX="flex-end">
                        <I18n id="tableHeader.amount" />
                    </GridTable.Data>
                    {isDesktop && (
                        <>
                            <GridTable.Data columnStart={5} alignX="flex-end">
                                <I18n id="tableHeader.balance" />
                            </GridTable.Data>
                        </>
                    )}
                </GridTable.Container>
            );
        }
        return null;
    };

    renderList = (list, renderLoadMore) => {
        const { operationType } = this.props;

        const isEmpty = list.length === 0;

        return (
            <Container className="container--layout scrollable movements-container">
                <GridTable>
                    <GridTable.Header>{this.renderHeaderList(operationType)}</GridTable.Header>
                    <GridTable.Body>
                        {list}
                        {!isEmpty && renderLoadMore()}
                    </GridTable.Body>
                </GridTable>
            </Container>
        );
    };

    getMovements = () => {
        const { operationType, movements, pageNumber, totalCountOnline } = this.props;

        if (operationType !== "accounts") {
            return movements;
        }

        const movementsFirstPage = configUtil.getInteger("movementsFirstPage");
        const movementsPerPage = configUtil.getInteger("movementsPerPage");

        const max = movementsFirstPage + (pageNumber - 1) * movementsPerPage;

        // si ya me estoy moviendo en los historicos
        if (totalCountOnline < max) {
            return movements;
        }

        // si todavia estoy paginando en movimientos online
        return movements.slice(0, max);
    };

    hasMoreMovements = () => {
        const { operationType, moreMovements } = this.props;

        if (operationType !== "accounts") {
            return moreMovements;
        }

        const { pageNumber, totalCountOnline } = this.props;
        const movementsFirstPage = configUtil.getInteger("movementsFirstPage");
        const movementsPerPage = configUtil.getInteger("movementsPerPage");

        const max = movementsFirstPage + (pageNumber - 1) * movementsPerPage;
        return totalCountOnline >= max || moreMovements;
    };

    render() {
        const { firstFetched, isFetchingMovements, filters, operationType, helpMessage } = this.props;

        const noDataText = helpMessage ? helpMessage : (filters.advanced ? `${operationType}.movements.advanced.none` : `${operationType}.movements.none`);
        return (
            <ProductList
                fetching={isFetchingMovements}
                items={this.getMovements()}
                renderItem={this.renderItem}
                lastPage={!this.hasMoreMovements()}
                onLoadMoreClick={this.handleClick}
                firstFetched={firstFetched}
                filters={filters}
                noMoreDataText={`${operationType}.movements.noMoreMovements`}
                loadMoreText={`${operationType}.searchMovements`}
                noDataText={ noDataText }
                isHelpText={!!helpMessage}
                noFiltersDataText={`${operationType}.movements.filters.none`}>
                {this.renderList}
            </ProductList>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    filters: accountsSelectors.getFilters(state),
    firstFetched: accountsSelectors.getFirstFetched(state),
    movements: accountsSelectors.getMovements(state),
    moreMovements: accountsSelectors.getMoreMovements(state),
    pageNumber: accountsSelectors.getPageNumber(state),
    isFetchingMovements: accountsSelectors.getFetchingMovements(state),
    totalCountOnline: accountsSelectors.getTotalCount(state),

    deposit: depositsSelectors.getSelectedDeposit(state),
    totalDeposits: depositsSelectors.getTotalDeposits(state),
    channels: configUtil.getArray("core.enabledChannels"),
    fetching: depositsSelectors.getFetching(state),
    fetchingDownload: depositsSelectors.getFetchingDownload(state),
    fetchingMovements: depositsSelectors.getFetchingMovements(state),
    totalCount: depositsSelectors.getTotalCount(state),
    activeRegion: loginSelectors.getRegion(state),
    fullList: depositsSelectors.getFullList(state), // TODO ¿Hay que sacar el valor de algun lado? ejemplo configuration
    totalFavorites: depositsSelectors.getTotalFavorites(state),

    offset: accountsSelectors.getOffset(state),
    quantity: accountsSelectors.getQuantity(state),
    helpMessage: accountsSelectors.getHelpMessage(state),
});

export default withRouter(connect(mapStateToProps)(MovementsTable));
