import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import { isArray } from "lodash-es";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import { array, arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class ProductList extends Component {
    static propTypes = {
        onLoadMoreClick: func.isRequired,
        renderItem: func.isRequired,
        children: func.isRequired,
        noDataText: string.isRequired,
        // we don't care about the shape of item because we don't handle the renderItem in this component
        // eslint-disable-next-line react/forbid-prop-types
        items: array,
        loadMoreText: string,
        noMinHeight10rem: bool,
        noMoreDataText: string,
        noFiltersDataText: string,
        fetching: bool,
        lastPage: bool,
        pageNumber: number,
        firstFetched: bool,
        filters: shape({}),
        politeMessage: shape({}),
        match: shape({
            params: shape({ idForm: string }),
        }),
        lines: arrayOf(shape({})),
        isHelpText: bool,
    };

    static defaultProps = {
        fetching: false,
        filters: {},
        firstFetched: true,
        items: [],
        lastPage: true,
        lines: undefined,
        loadMoreText: "",
        match: undefined,
        noMinHeight10rem: false,
        noFiltersDataText: "accounts.movements.filters.none",
        noMoreDataText: "",
        pageNumber: 0,
        politeMessage: React.createRef(),
        isHelpText: false,
    };

    handleClick = () => {
        const { onLoadMoreClick, pageNumber } = this.props;
        onLoadMoreClick(pageNumber);
    };

    renderPoliteMessage = (message) => {
        const { politeMessage, isHelpText } = this.props;
        if (politeMessage && politeMessage.current) {
            politeMessage.current.innerText = isHelpText ? message : i18n.get(message);
        }
    };

    // special case for in memory front pagination. Manuallt add lines
    manageSalaryPaymentCase = () => {
        const { fetching, loadMoreText, noMoreDataText, items, lines } = this.props;
        if ((loadMoreText || fetching) && items.length < lines.length) {
            return (
                <Box display="flex" fullWidth alignX="center" className="no-more-data">
                    <Button
                        bsStyle="link"
                        onClick={this.handleClick}
                        image="images/arrow-down.svg"
                        loading={fetching}
                        label={loadMoreText}
                        className="btn-small"
                    />
                </Box>
            );
        }
        return (
            <Box display="flex" fullWidth alignX="center" className="no-more-data">
                <Text labelKey={noMoreDataText} component="p" align="center" bold size="6" color="text" />
            </Box>
        );
    };

    renderLoadMore = () => {
        const { fetching, loadMoreText, lastPage, noMoreDataText, items, match } = this.props;
        const isEmpty = items.length === 0;
        if (lastPage) {
            if (!isEmpty && noMoreDataText) {
                this.renderPoliteMessage(noMoreDataText);
            }
            return (
                !isEmpty &&
                noMoreDataText && (
                    <Box display="flex" fullWidth alignX="center" className="no-more-data">
                        <Text labelKey={noMoreDataText} component="p" align="center" bold size="6" color="text" />
                    </Box>
                )
            );
        }

        if (match && match.params && match.params.idForm === SALARY_PAYMENT_ID_FORM) {
            return this.manageSalaryPaymentCase();
        }

        // TODO doing a workaround here to show a loader when fetching more items on mobile
        // it will be better that I pass a loading label from props and then show it when fetching
        return (
            <Box display="flex" fullWidth alignX="center" className="no-more-data">
                <Button
                    bsStyle="link"
                    onClick={this.handleClick}
                    image="images/arrow-down.svg"
                    imageRight
                    loading={fetching}
                    label={loadMoreText}
                    className="btn-small"
                />
            </Box>
        );
    };

    render() {
        const {
            firstFetched,
            fetching,
            filters,
            children,
            items,
            renderItem,
            noMinHeight10rem,
            noDataText,
            noFiltersDataText,
            politeMessage,
            isHelpText,
        } = this.props;
        let withFilters = true;
        const isEmpty = items.length === 0;

        Object.keys(filters).map((key) => {
            if (filters[key]) {
                if (isArray(filters[key])) {
                    if (filters[key].length === 0) {
                        withFilters = false;
                    }
                }
            } else {
                withFilters = false;
            }
            return "";
        });

        if (withFilters && isEmpty) {
            this.renderPoliteMessage(noFiltersDataText);
        } else if (!firstFetched && isEmpty) {
            this.renderPoliteMessage(noDataText);
        }
        return (
            <Box {...(!noMinHeight10rem && { className: "min-height-10rem" })}>
                <PageLoading loading={isEmpty && fetching}>
                    <Box fullHeight fullWidth>
                        {children(
                            items.map((stat, index) => renderItem(stat, index)),
                            this.renderLoadMore,
                        )}
                        {isEmpty && (
                            <Box
                                display="flex"
                                fullWidth
                                alignX="center"
                                alignY="center"
                                className="no-more-data min-height-7-5rem"
                                key="noMoreMovements"
                                fullHeight>
                                <Text
                                    labelKey={isHelpText ?"" : (withFilters && isEmpty ? noFiltersDataText : noDataText)}
                                    component="p"
                                    align="center"
                                    size="5"
                                    color="text"
                                    className="px-5"
                                >
                                    {isHelpText ? noDataText : ""}
                                </Text>
                            </Box>
                        )}
                        <div ref={politeMessage} className="visually-hidden" aria-live="polite" />
                    </Box>
                </PageLoading>
            </Box>
        );
    }
}

export default ProductList;
