import { REGION_USA } from "constants.js";
import { resizableRoute } from "pages/_components/Resizable";
import SettingsOption from "pages/settings/_components/SettingsOption";
import Avatar from "pages/_components/Avatar";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as loginSelectors } from "reducers/login";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import {
    actions as updateUserDataActions,
    selectors as updateUSerDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { maskEmail } from "util/format";
import SideBarModal from "pages/_components/modal/SideBarModal";
import UpdateUserDataStepZero from "pages/updateUserData/UpdateUserDataStepZero";

class Settings extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        activeRegion: string,
        environments: objectOf(
            shape({
                environmentType: string,
                idEnvironment: string,
                name: string,
            }),
        ),
        isMobile: bool.isRequired,
        user: shape({
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            userFullName: string,
        }),
        activeEnvironment: shape(),
        isShowUpdateUserDataStepZero: bool,
        isTablet: bool.isRequired,
    };

    static defaultProps = {
        activeEnvironment: null,
        activeRegion: null,
        environments: null,
        user: null,
        isShowUpdateUserDataStepZero: false,
    };

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(updateUserDataActions.hideUpdateUserDataStepZero());
    }

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/desktop"));
    };

    validateClientRemediation = () => {
        const { dispatch } = this.props;
        dispatch(updateUserDataActions.validateClientRemediation());
    };

    showUpdateUserDataStepZero = () => {
        const { dispatch } = this.props;
        dispatch(updateUserDataActions.showUpdateUserDataStepZero());
    };

    hideUpdateUserDataStepZero = () => {
        const { dispatch } = this.props;
        dispatch(updateUserDataActions.hideUpdateUserDataStepZero());
    };

    render() {
        const {
            environments,
            isMobile,
            user,
            activeRegion,
            isShowUpdateUserDataStepZero,
            isTablet,
            activeEnvironment,
        } = this.props;
        const { defaultAvatarId, email, userFullName } = user;

        return (
            <>
                <Notification scopeToShow="settings" />
                <Box display="flex" alignX="center" className="mx-md-n-7" background="heading-color">
                    <Head
                        {...(isTablet ? { additionalClassName: "px-7 pb-md-0" } : { additionalClassName: "pb-md-0" })}
                        lightText
                        title={isMobile ? "menu.settings" : "settings.index"}
                        onBack={this.handleBack}
                    />
                </Box>

                {!isMobile && (
                    <Box
                        component="section"
                        background="heading-color"
                        display="flex"
                        className="pb-5 mx-md-n-7 mt-n-min"
                        {...(isMobile && { column: true })}
                        alignX="center"
                        alignY="center">
                        <Avatar
                            className="avatar-transparent"
                            noImage
                            user={{ userFullName, defaultAvatarId, email }}
                        />
                        <Box display="flex" column alignY="flex-start" alignX="center">
                            <Box display="flex" alignY="center">
                                <Image src="images/icons/user.svg" wrapperClassName="fit-content" className="mr-3" />
                                <Text color="inverse" size="4" bold>
                                    {userFullName}
                                </Text>
                            </Box>
                            <Text size="6" color="inverse">
                                {maskEmail(email)}
                            </Text>
                        </Box>
                    </Box>
                )}

                <MainContainer>
                    {isMobile && (
                        <Box
                            component="section"
                            background="heading-color"
                            display="flex"
                            className="pb-5 mx-n-5"
                            {...(isMobile && { column: true })}
                            alignX="center"
                            alignY="center">
                            <Avatar
                                className="avatar-transparent"
                                noImage
                                user={{ userFullName, defaultAvatarId, email }}
                            />
                            <Box display="flex" column alignY="center" alignX="center">
                                <Box display="flex" alignY="center">
                                    <Image
                                        src="images/icons/user.svg"
                                        wrapperClassName="fit-content"
                                        className="mr-3"
                                    />
                                    <Text color="inverse" size="4" bold>
                                        {userFullName}
                                    </Text>
                                </Box>
                                <Text size="6" color="inverse">
                                    {maskEmail(email)}
                                </Text>
                            </Box>
                        </Box>
                    )}
                    <Box component="section" className="settings-wrapper pt-6 pt-md-11" ref={this.containerRef}>
                        <Row gapY="3">
                            <Col xs={12} md={6}>
                                <Row gapY="0">
                                    <Col xs={12}>
                                        <Box display="flex" alignY="center" className="title-wrapper" fullWidth>
                                            <Text
                                                size={isMobile ? "3" : "5"}
                                                color="primary"
                                                bold
                                                component="h2"
                                                className="mt-0 mb-0 ml-md-4"
                                                labelKey="settings.title.personal"
                                            />
                                        </Box>
                                        <Box component="ul">
                                            <SettingsOption
                                                linkTo="/settings/changeEmail"
                                                messageKey="settings.options.changeEmail"
                                            />
                                            <SettingsOption
                                                linkTo="/settings/changePhone"
                                                messageKey="settings.options.changePhone"
                                            />
                                            {activeEnvironment && activeEnvironment.type !== "corporate" && (
                                                <SettingsOption
                                                    onClick={this.validateClientRemediation}
                                                    messageKey="settings.options.updateUserData"
                                                />
                                            )}
                                        </Box>
                                    </Col>
                                    <Col xs={12}>
                                        <Box display="flex" alignY="center" className="title-wrapper" fullWidth>
                                            <Text
                                                size={isMobile ? "3" : "5"}
                                                color="primary"
                                                bold
                                                component="h2"
                                                className="mt-0 mb-0 ml-md-4"
                                                labelKey="settings.title.security"
                                            />
                                        </Box>
                                        <Box component="ul">
                                            <SettingsOption
                                                linkTo="/settings/authenticatorHandler"
                                                messageKey="settings.options.authenticateHandler"
                                            />
                                            <SettingsOption
                                                linkTo="/settings/myDevices"
                                                messageKey="settings.options.myDevices"
                                            />
                                        </Box>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} md={6}>
                                <Box display="flex" alignY="center" className="title-wrapper" fullWidth>
                                    <Text
                                        size={isMobile ? "3" : "5"}
                                        color="primary"
                                        bold
                                        component="h2"
                                        className="mt-0 mb-0 ml-md-4"
                                        labelKey="settings.title.configuration"
                                    />
                                </Box>
                                <Box component="ul">
                                    <SettingsOption
                                        linkTo="/settings/fingerprintConfiguration"
                                        messageKey="settings.options.biometricsConfiguration"
                                    />
                                    <SettingsOption
                                        linkTo="/settings/pushNotifications"
                                        messageKey="settings.options.pushNotifications"
                                    />
                                    <SettingsOption
                                        linkTo="/settings/notificationsConfiguration"
                                        messageKey="settings.options.notificationsConfiguration"
                                    />

                                    {environments &&
                                        Object.keys(environments).length > 1 &&
                                        activeEnvironment.type === "corporate" && (
                                            <SettingsOption
                                                linkTo="/settings/setDefaultEnvironment"
                                                messageKey="settings.options.setDefaultEnvironment"
                                            />
                                        )}

                                    <SettingsOption
                                        linkTo="/settings/changeLanguage"
                                        messageKey="settings.options.changeLanguage"
                                    />
                                </Box>
                            </Col>

                            {activeRegion === REGION_USA && (
                                <Col md={6} sm={12}>
                                    <Text
                                        size={isMobile ? "3" : "5"}
                                        color="primary"
                                        bold
                                        component="h2"
                                        className="mt-1 mb-3 mb-md-3 ml-md-4"
                                        labelKey="settings.title.documents"
                                    />
                                    <Box component="ul">
                                        <SettingsOption
                                            linkTo="/settings/esign/documents"
                                            messageKey="settings.options.documents.termsAndConditions"
                                        />
                                    </Box>
                                </Col>
                            )}
                        </Row>
                    </Box>
                </MainContainer>
                <SideBarModal
                    show={isShowUpdateUserDataStepZero || false}
                    onClose={this.hideUpdateUserDataStepZero}
                    title="settings.userData.update.pre.stepZero.title">
                    <UpdateUserDataStepZero />
                </SideBarModal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    user: sessionSelectors.getUser(state),
    activeRegion: loginSelectors.getRegion(state),
    isShowUpdateUserDataStepZero: updateUSerDataSelectors.isShowUpdateUserDataStepZero(state),
});

export default connect(mapStateToProps)(resizableRoute(Settings));
