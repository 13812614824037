import classNames from "classnames";
import { COMPANY_NAME, CORPORATE_ENVIRONMENT_TYPE } from "constants.js";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MovementsTable from "pages/_components/MovementsTable";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import ProductFilters from "pages/_components/ProductFilters";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import LoadingModal from "pages/_components/modal/LoadingModal";
import SideBarModal from "pages/_components/modal/SideBarModal";
import DetailHeadInfo from "pages/accounts/_components/DetailHeadInfo";
import MoreFilters from "pages/accounts/_components/MoreFilters";
import DownloadAccoutDetailForm from "pages/forms/customForms/DownloadAccoutDetailForm";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as checksSelectors } from "reducers/checks";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as loginSelectors } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { selectors as widgetSelectors } from "reducers/widgets";
import * as configUtil from "util/config";
import * as dateUtils from "util/date";
import { shareSocialText } from "util/download";
import * as i18nUtils from "util/i18n";
import { getLocationBasePath } from "util/revelock";
import { selectors as sessionSelectors } from "reducers/session";
import MovementsBlocked from "./_components/MovementsBlocked";

const PAGE_IDENTIFIER = "accounts.read";
class Details extends Component {
    state = {
        currentMonthPeriod: null,
        lastMonthPeriod: null,
        selectedFilter: "all",
        showSideBarDownloadDetail: false,
        downloadingMovements: false,
    };

    componentDidMount() {
        const { dispatch, accounts, accountsWidget, ...rest } = this.props;

        // si no hay cuentas cargadas redirijo al desktop
        if ((!accounts || accounts.length === 0) && (!accountsWidget || accountsWidget.length === 0)) {
            dispatch(replace("/desktop"));
        } else {
            dispatch(accountsActions.clearTrackingTransferUrlRequest());
            dispatch(accountsActions.resetFilters());
            dispatch(accountsActions.readAccount(rest.match.params.id));

            dispatch(accountsActions.details(rest.match.params.id, { advanced: false, periodFilter: "all" }));
            this.setState({
                currentMonthPeriod: dateUtils.getCurrentMonthPeriod(),
                lastMonthPeriod: dateUtils.getLastMonthPeriod(),
            });
        }
        this.setRevelockLocation();
    }

    componentDidUpdate() {
        const { currentFilters } = this.props;
        const { selectedFilter } = this.state;

        if (selectedFilter && currentFilters?.advanced) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                selectedFilter: null,
            });
        }
    }

    /**
     * Set position revelock
     */

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(accountsActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { lastMonthPeriod, currentMonthPeriod } = this.state;
        const { dispatch, ...rest } = this.props;

        this.setState({ selectedFilter: id });

        let period = [];
        switch (id) {
            case "currentMonth":
                period = currentMonthPeriod;
                break;
            case "lastMonth":
                period = lastMonthPeriod;
                break;
            case "all":
            default:
                break;
        }

        const filters = { advanced: false, dateFrom: period[0], dateTo: period[1], periodFilter: id };
        dispatch(accountsActions.fetchMovements(rest.match.params.id, filters));
    };

    onFinishDownload = () => {
        this.setState({
            downloadingMovements: false,
        });
    };

    handleClickDownload = (format) => {
        const { dispatch, match } = this.props;
        this.setState({
            downloadingMovements: true,
        });

        dispatch(accountsActions.downloadMovements(match.params.id, format, this.onFinishDownload));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/desktop"));
    };

    getMergedAccount = () => {
        const { account, accounts, accountsWidget } = this.props;
        let accountFromList = null;

        if (account) {
            if (accounts && accounts.length > 0) {
                accountFromList = accounts?.find((a) => a.idProduct === account.idProduct);
            } else if (accountsWidget && accountsWidget.length > 0) {
                accountFromList = accountsWidget?.find((a) => a.idProduct === account.idProduct);
            }
        }

        let mergedAccount = null;
        if (accountFromList) {
            mergedAccount = {
                ...account,
                status: accountFromList.status,
                isValidToReferenceLetter: accountFromList.isValidToReferenceLetter,
            };
        }

        return mergedAccount;
    };

    existsAnotherAccount = () => {
        const { account, accounts, accountsWidget } = this.props;

        if (accounts?.length > 0) {
            return accounts.some((a) => a.idProduct !== account.idProduct);
        }
        if (accountsWidget?.length > 0) {
            return accountsWidget.some((a) => a.idProduct !== account.idProduct);
        }
        return false;
    };

    getContextOptions = (mergedAccount) => {
        const { account, dispatch, activeEnvironment } = this.props;

        const contextOptions = [
            {
                label: "accounts.buttons.transfer",
                onClick: () => {
                    if (mergedAccount.status !== "ACTIVA") {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("accounts.buttons.inactiveAccount"),
                                "error",
                                ["account/details"],
                            ),
                        );
                        return;
                    }
                    if (
                        !account?.permissions?.["transfer.internal"] &&
                        !account?.permissions?.["transfer.thirdParties"] &&
                        !account?.permissions?.["transfer.local"] &&
                        !account?.permissions?.["transfer.foreign"]
                    ) {
                        // error sin permisos necesarios
                        dispatch(
                            notificationActions.showNotification(i18nUtils.get("returnCode.COR005E"), "error", [
                                "account/details",
                            ]),
                        );
                    } else if (
                        !this.existsAnotherAccount() &&
                        !account?.permissions?.["transfer.local"] &&
                        !account?.permissions?.["transfer.thirdParties"] &&
                        !account?.permissions?.["transfer.foreign"]
                    ) {
                        // error sin productos ni permisos necesarios
                        dispatch(
                            notificationActions.showNotification(i18nUtils.get("returnCode.API040E"), "error", [
                                "account/details",
                            ]),
                        );
                    } else if (this.existsAnotherAccount() && account?.permissions?.["transfer.internal"]) {
                        dispatch(push(`/formCustom/transferInternal?debitAccount=${account.idProduct}`));
                    } else if (
                        account?.permissions?.["transfer.thirdParties"] ||
                        account?.permissions?.["transfer.local"]
                    ) {
                        dispatch(push(`/formCustom/transferLocal?debitAccount=${account.idProduct}`));
                    } else if (!account?.permissions?.["transfer.foreign"]) {
                        dispatch(push(`/formCustom/transferForeign?debitAccount=${account.idProduct}`));
                    }
                },
            },
            {
                label: "accounts.buttons.servicePayments",
                onClick: () => {
                    if (mergedAccount.status !== "ACTIVA") {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("accounts.buttons.inactiveAccount"),
                                "error",
                                ["account/details"],
                            ),
                        );
                        return;
                    }
                    dispatch(push("/servicePayments"));
                },
            },
            {
                label: "accounts.buttons.cardsPayment",
                onClick: () => {
                    if (mergedAccount.status !== "ACTIVA") {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("accounts.buttons.inactiveAccount"),
                                "error",
                                ["account/details"],
                            ),
                        );
                        return;
                    }
                    if (
                        activeEnvironment.permissions.payCreditCard ||
                        activeEnvironment.permissions.payCreditCardOther ||
                        activeEnvironment.permissions.payCreditCardThird
                    ) {
                        if (activeEnvironment.permissions.payCreditCard) {
                            dispatch(push("/formCustom/payCreditCardLocal"));
                        } else if (activeEnvironment.permissions.payCreditCardThird) {
                            dispatch(push("/formCustom/payCreditCardThird"));
                        }
                    } else {
                        dispatch(push("/formCustom/payCreditCardLocal"));
                    }
                },
            },
            {
                label: "reference.letter.account.redirect",
                onClick: () => {
                    if (mergedAccount.status === "ACTIVA") {
                        if (mergedAccount.isValidToReferenceLetter) {
                            dispatch(
                                push(
                                    `/formCustom/referenceLetter?debitAccount=${account.idProduct}&currency=${account.currency}`,
                                ),
                            );
                        } else {
                            // error does not meet the time required for
                            dispatch(
                                notificationActions.showNotification(
                                    i18nUtils.get("reference.letter.account.redirect.error", null, {
                                        MONTHS: configUtil.getInteger(
                                            "form.reference.letter.field.account.monthToBeValid",
                                        ),
                                    }),
                                    "error",
                                    ["account/details"],
                                ),
                            );
                        }
                    } else {
                        // error does not meet the status required for
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("reference.letter.account.redirect.TimeError"),
                                "error",
                                ["account/details"],
                            ),
                        );
                    }
                },
            },
        ];

        if (account?.productType === "CC") {
            contextOptions.unshift({
                label: "accounts.buttons.checkbooks",
                onClick: () => {
                    dispatch(push(`/checkbooks/${account.idProduct}`));
                },
            });
        }
        return contextOptions;
    };

    getSecondaryBtn = () => [
        {
            bsStyle: "outline",
            label: "accounts.buttons.statement",
            onClick: () => {
                this.setState({ showSideBarDownloadDetail: true });
            },
        },
    ];

    getAccountShare = (mergedAccount) => {
        if (!mergedAccount) {
            return undefined;
        }

        const { client, number: accountNumber, productType } = mergedAccount;
        if (!accountNumber || !productType) {
            return undefined;
        }
        return `${client?.name || ""}\n${COMPANY_NAME}\n${i18nUtils.get(
            `productType.${productType}`,
        )} ${accountNumber}`;
    };

    getMobileOptions = (mergedAccount) =>
        [
            {
                label: "accounts.buttons.editAlias",
                onClick: () => {
                    const { dispatch, account } = this.props;
                    dispatch(push(`/accounts/${account.idProduct}/setAlias`));
                },
            },
        ]
            .concat(this.getSecondaryBtn())
            .concat(this.getContextOptions(mergedAccount))
            .concat([
                {
                    label: "accounts.buttons.share",
                    onClick: () => {
                        const textShared = this.getAccountShare(mergedAccount);
                        if (textShared && textShared.length > 0) {
                            shareSocialText(textShared);
                        }
                    },
                },
            ]);

    render() {
        const { selectedFilter, downloadingMovements, showSideBarDownloadDetail } = this.state;
        const {
            totalCount,
            account,
            channels,
            fetching,
            fetchingMovements,
            dispatch,
            isDesktop,
            isTablet,
            activeRegion,
            totalFavorites,
            currentLang,
            accounts,
            accountsWidget,
            currentFilters,
            isTrackingUrlFetch,
            isCheckUrlFetch,
            activeEnvironment,
            ...rest
        } = this.props;

        const isCorporate = activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE;

        const mergedAccount = this.getMergedAccount(account);

        const filters = [
            <Button
                bsStyle="chip"
                className={classNames({
                    "is-active": selectedFilter === "all",
                })}
                key="all"
                label={isCorporate ?"accounts.movements.filters.channel.option.all.corporate" : "accounts.movements.filters.channel.option.all"}
                onClick={() => this.handleFilterButtonsClick("all")}
                image={selectedFilter === "all" ? "images/check.svg" : ""}
            />,
            <Button
                bsStyle="chip"
                className={classNames({
                    "is-active": selectedFilter === "currentMonth",
                })}
                key="currentMonth"
                label="accounts.movements.filters.currentMonth"
                onClick={() => this.handleFilterButtonsClick("currentMonth")}
                image={selectedFilter === "currentMonth" ? "images/check.svg" : ""}
            />,
            <Button
                bsStyle="chip"
                className={classNames({
                    "is-active": selectedFilter === "lastMonth",
                })}
                key="lastMonth"
                label="accounts.movements.filters.lastMonth"
                onClick={() => this.handleFilterButtonsClick("lastMonth")}
                image={selectedFilter === "lastMonth" ? "images/check.svg" : ""}
            />,
        ];

        const documents = [
            ...(isCorporate
                ? []
                : [
                      {
                          label: "accounts.pdfFile",
                          onClick: () => this.handleClickDownload("pdf"),
                      },
                  ]),
            {
                label: "accounts.xlsFile",
                onClick: () => this.handleClickDownload("xls"),
            },
            ...(isCorporate
                ? []
                : [
                      {
                          label: "accounts.csvFile",
                          onClick: () => this.handleClickDownload("csv"),
                      },
                  ]),
        ];

        const contextOptions = this.getContextOptions(mergedAccount);
        const mobileOptions = this.getMobileOptions(mergedAccount);
        const secondaryBtn = this.getSecondaryBtn();

        const initialFilterDate = configUtil.getDate(
            "accounts.movements.initialFilterDate",
            "DD/MM/YYYY",
            "01/08/2019",
        );

        return (
            <>
                <PageLoading loading={fetching} classicStyle={false}>
                    {!fetching && mergedAccount && (
                        <>
                            <Notification scopeToShow="account/details" />

                            <ProductDetail>
                                <ProductDetail.Header
                                    contextOptions={isDesktop ? contextOptions : mobileOptions}
                                    dispatch={dispatch}
                                    handleOptionsClick={this.handleClick}
                                    isDesktop={isDesktop}
                                    onBack={this.handleBack}
                                    productId={rest.match.params.id}
                                    product={mergedAccount}
                                    secondaryBtn={secondaryBtn}
                                    totalFavorites={0}>
                                    {isDesktop ? (
                                        <DetailHeadInfo {...this.props} account={mergedAccount} />
                                    ) : (
                                        <RemarkableProductInfo
                                            currency={account.currency}
                                            quantity={account.balance}
                                            status={mergedAccount.status}
                                            isDesktop={isDesktop}
                                            productType={account.productType}
                                            shortLabel={account.shortLabel}
                                            accountNumber={account.number}
                                            idProduct={account.idProduct}
                                        />
                                    )}
                                </ProductDetail.Header>

                                <ProductDetail.Body
                                    id="productDetail.body"
                                    closeMoreFiltersWhenSiblingClick
                                    isDesktop={isDesktop}
                                    product={mergedAccount}
                                    productKind="accounts"
                                    filters={filters}
                                    documents={documents}
                                    {...(mergedAccount.isTherePendingTransaction && {
                                        disclaimerStyled: "warning",
                                        disclaimerLabelkey: "accounts.disclaimer.pendingTransaction",
                                    })}
                                    moreFilters={
                                        <MoreFilters
                                            channels={channels}
                                            currency={account.currency}
                                            dispatch={dispatch}
                                            isDesktop={isDesktop}
                                            isSubmitting={rest.isSubmitting}
                                            productId={rest.match.params.id}
                                        />
                                    }
                                    {...(!isDesktop && {
                                        detailHeadInfo: <DetailHeadInfo {...this.props} account={mergedAccount} />,
                                    })}>
                                    <Box
                                        isDesktop={isDesktop}
                                        iconLabel="images/last-movements.svg"
                                        keyLabel="accounts.movements">
                                        {!fetchingMovements && (
                                            <ProductFilters
                                                documents={documents}
                                                notCollapsedFilters={false}
                                                downloading={downloadingMovements}
                                                handleFilterButtonsClick={() => {
                                                    this.handleFilterButtonsClick();
                                                }}
                                                visibleFilterBtnLabelOnOpen
                                                moreFilters={
                                                    <MoreFilters
                                                        channels={channels}
                                                        currency={account.currency}
                                                        dispatch={dispatch}
                                                        isDesktop={isDesktop}
                                                        isSubmitting={rest.isSubmitting}
                                                        productId={rest.match.params.id}
                                                        minDate={initialFilterDate}
                                                        filterOptions={[
                                                            {
                                                                value: "period",
                                                                label: i18nUtils.get(
                                                                    "accounts.movements.filters.searchBy.period",
                                                                ),
                                                            },
                                                            {
                                                                value: "amount",
                                                                label: i18nUtils.get(
                                                                    "accounts.movements.filters.searchBy.amount",
                                                                ),
                                                            },
                                                            {
                                                                value: "yearAndMonth",
                                                                label: i18nUtils.get(
                                                                    "accounts.movements.filters.searchBy.yearAndMonth",
                                                                ),
                                                            },
                                                        ]}
                                                    />
                                                }>
                                                {filters}
                                            </ProductFilters>
                                        )}
                                        <MovementsTable
                                            isDesktop={isDesktop}
                                            isTablet={isTablet}
                                            operationId={account.idProduct}
                                            operationCurrency={account.currency}
                                            operationType="accounts"
                                        />
                                    </Box>
                                    <Box
                                        isDesktop={isDesktop}
                                        iconLabel="images/block-balances.svg"
                                        keyLabel="accounts.balanceDetail">
                                        <MovementsBlocked productId={account.idProduct} />
                                    </Box>
                                </ProductDetail.Body>
                            </ProductDetail>
                        </>
                    )}
                </PageLoading>
                <SideBarModal
                    show={showSideBarDownloadDetail}
                    onClose={() => {
                        this.setState({ showSideBarDownloadDetail: false });
                    }}
                    title="forms.product.state.download.title">
                    <DownloadAccoutDetailForm
                        isDesktop={isDesktop}
                        account={mergedAccount}
                        currentLang={currentLang}
                        dispatch={dispatch}
                    />
                </SideBarModal>
                {(isTrackingUrlFetch || isCheckUrlFetch) && (
                    <div className="modal-container">
                        <LoadingModal
                            showModal={isTrackingUrlFetch || isCheckUrlFetch}
                            content={i18nUtils.get(
                                `${
                                    isCheckUrlFetch
                                        ? "checks.getUrl.loading.content"
                                        : "transfer.foreign.tracking.getUrl.loading.content"
                                }`,
                            )}
                        />
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    totalAccounts: accountsSelectors.getTotalAccounts(state),
    channels: configUtil.getArray("core.enabledChannels"),
    fetching: accountsSelectors.getFetchingDetail(state),
    fetchingMovements: accountsSelectors.getFetchingMovements(state),
    totalCount: accountsSelectors.getTotalCount(state),
    activeRegion: loginSelectors.getRegion(state),
    totalFavorites: accountsSelectors.getTotalFavorites(state),
    currentLang: i18nSelectors.getLang(state),
    currentFilters: accountsSelectors.getFilters(state),

    accounts: accountsSelectors.getAccounts(state),
    accountsWidget: widgetSelectors.getWidget(state, "accounts")?.data?.accounts,
    isTrackingUrlFetch: accountsSelectors.isTrackingUrlFetch(state),
    isCheckUrlFetch: checksSelectors.isFetchingCheckUrl(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

Details.propTypes = {
    account: shape({
        productType: string,
        idProduct: string,
        permissions: shape({}),
        currency: string,
    }),
    accounts: arrayOf(shape({})),
    accountsWidget: arrayOf(shape({})),
    activeEnvironment: shape({}).isRequired,
    activeRegion: string,
    channels: arrayOf(string).isRequired,
    currentFilters: shape({}),
    currentLang: string,
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    fetchingMovements: bool.isRequired,
    isCheckUrlFetch: bool.isRequired,
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    isTrackingUrlFetch: bool,
    match: shape({}).isRequired,
    setLocationCustomPath: func.isRequired,
    totalAccounts: number.isRequired,
    totalCount: number.isRequired,
    totalFavorites: number.isRequired,
};

Details.defaultProps = {
    account: null,
    activeRegion: null,
    currentLang: "",
    accounts: null,
    accountsWidget: null,
    currentFilters: null,
    isTrackingUrlFetch: false,
};

export default connect(mapStateToProps)(withRevelockPositionContext(Details));
