import React, { useMemo } from "react";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";

const TRANSACTION_PROCESSING_STATUS_LIST = ["PROCESSING"];
const useAsyncTicketProcessing = (transaction) => {
    const tooltipProcessingComponent = useMemo(() => {
        if (
            transaction?.idTransactionStatus &&
            TRANSACTION_PROCESSING_STATUS_LIST.includes(transaction?.idTransactionStatus) &&
            (!transaction?.data?.backendReference || transaction.data.backendReference === "")
        ) {
            return (
                <Box className="mr-auto" display="flex" alignY="center">
                    <Image wrapperClassName="fit-content" className="mr-3 mt-2" src="images/icons/tooltip.svg" />
                    <Text size="7" className="my-3 my-md-0" labelKey="transaction.async.processing.tooltip.help" />
                </Box>
            );
        }

        return <div />;
    }, [transaction]);

    return [tooltipProcessingComponent];
};

export default useAsyncTicketProcessing;
