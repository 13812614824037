import { CORPORATE_GROUP_ENVIRONMENT_TYPE, CORPORATE_ENVIRONMENT_TYPE } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import MenuChat from "pages/_components/menu/MenuChat";
import MenuDropdown from "pages/_components/menu/MenuDropdown";
import MenuLink from "pages/_components/menu/MenuLink";
import { bool, func, node, number, object, shape, string } from "prop-types";
import React from "react";
import Nav from "react-bootstrap/lib/Nav";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push, routerActions } from "react-router-redux/actions";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as productRequestActions } from "reducers/productRequest";
import { actions as updateCompanyDataActions } from "reducers/updateCompanyData/updateCompanyData.reducer";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as transactionsSelectors } from "reducers/transactions";
import {
    selectors as transactionalProfileSelectors,
    actions as transactionalProfileActions,
} from "reducers/transactionalProfile";
import { selectors as formSelectors } from "reducers/form";

// Wally
import isLocalPhoneNumber from "util/mobileNumber";
import { isMobileNativeFunc } from "util/device";

import { get as getMessage } from "util/i18n";
import kuaraVersionNumber from "util/kuaraVersionNumber";
import { actions as kuaraActions } from "reducers/kuara";
import { actions as wallyActions } from "reducers/wally";
import FeatureFlag from "../../FeatureFlag";

class Menu extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            synchProducts: false,
        };
    }

    displayUpdateCompanyDataStepZero = () => {
        const { dispatch } = this.props;
        dispatch(updateCompanyDataActions.showUpdateCompanyDataStepZero());
    };

    displayTransactionalProfileDrawer = () => {
        const { dispatch } = this.props;
        dispatch(transactionalProfileActions.transactionalCountriesSettingRequest());
        dispatch(transactionalProfileActions.modalShow({}, true));
    };

    buildAdministrationMenu = () => {
        const { isMobile, isAdministrator } = this.props;
        const admMenuLinks = [];
        /* const buttonCompanyDataUpdate = (
            <Button
                label="administration.menu.companyDataUpdate"
                bsStyle="menu-synchronization"
                key="menu.administration.companyDataUpdate"
                onClick={this.displayUpdateCompanyDataStepZero}
            />
        ); */

        if (!isMobile && isAdministrator) {
            admMenuLinks.push(
                <>
                    <MenuLink
                        titleKey="administration.menu.users"
                        key="menu.administration.users"
                        linkTo="/administration/users"
                        exact
                    />
                    {/* NO BORRAR (IMPLEMENTACION EMPRESAS) */}
                    {/* <MenuLink
                        titleKey="administration.menu.groups"
                        key="menu.administration.groups"
                        linkTo="/administration/groups"
                        exact
                    /> */}
                    <MenuLink
                        titleKey="administration.restrictions.environment.title"
                        key="menu.administration"
                        linkTo="/administration/restrictions"
                        exact
                    />
                    {/* buttonCompanyDataUpdate */}
                </>,
            );
        } /* else if (!isMobile && activeEnvironment.permissions.settingsCorporateDataUpdate) {
            admMenuLinks.push(buttonCompanyDataUpdate);
        } */

        if (admMenuLinks.length > 0) {
            return (
                <MenuDropdown
                    titleKey="administration.menu.name"
                    key="menu.administration"
                    image="images/icons/menu-administration.svg">
                    {admMenuLinks}
                </MenuDropdown>
            );
        }
        return null;
    };

    syncEnvironmentProducts = () => {
        const { dispatch, isfetchingSynchronization } = this.props;

        this.setState({
            synchProducts: true,
        });

        if (!isfetchingSynchronization) {
            dispatch(
                productActions.syncEnviromentProduct(false, () => {
                    this.setState({
                        synchProducts: false,
                    });
                }),
            );
        }
    };

    navigateToWally = () => {
        const { dispatch } = this.props;
        if (kuaraVersionNumber(2)) {
            dispatch(wallyActions.setAccessFromMenu(true));
            dispatch(kuaraActions.userKuaraRequest());
        }
        dispatch(routerActions.push(kuaraVersionNumber(1) ? "/wally" : "/sendWally"));
    };

    displayProductRequestModal = () => {
        const { dispatch, onOptionClicked, isMobile } = this.props;
        dispatch(productRequestActions.modalShow());
        if (isMobile) {
            onOptionClicked();
        }
    };

    goToEnvironment = (idEnvironment) => {
        const { dispatch, environments } = this.props;
        if (environments[idEnvironment].allowedToAccess === "true") {
            dispatch(sessionActions.changeEnvironment(idEnvironment, false, null));
        } else {
            dispatch(
                notificationActions.showNotification(
                    getMessage("administration.restrictions.unavailableEnvironment"),
                    "error",
                    ["desktop"],
                ),
            );
            dispatch(push("/desktop"));
        }
    };

    render() {
        const {
            activeEnvironment,
            isMobile,
            isMobileNative,
            pendingTransactionsQuantity,
            isfetchingSynchronization,
            environments,
            fetchingTransactionalProfile,
            isFetchingBank,
        } = this.props;

        const { synchProducts } = this.state;

        if (!activeEnvironment) {
            return null;
        }

        // ENVIRONMENT
        let environmentMenuDropdown = null;
        const environmentMenuLinks = [];
        const buttonTransactionalProfile = (
            <Button
                label="transactional.profile.modal.title"
                bsStyle="menu-synchronization"
                key="transactional.profile.modal.title"
                onClick={this.displayTransactionalProfileDrawer}
                image="images/icons/union.svg"
                className="ml-0"
                disabled={fetchingTransactionalProfile || isFetchingBank}
            />
        );

        Object.values(environments)
            .filter((environment) => parseInt(environment.idEnvironment, 10) !== activeEnvironment.id)
            .forEach((environment) => {
                environmentMenuLinks.push(
                    <MenuLink
                        key={environment.name}
                        childTitleKey={environment.name}
                        onClick={() => this.goToEnvironment(environment.idEnvironment)}
                    />,
                );
            });

        environmentMenuDropdown = (
            <MenuDropdown
                id="menuTransfers"
                key="menu.transfers"
                useChildren={
                    <Box display="flex" alignY="center" className="mr-auto environment-wrapper">
                        <Image
                            src={`images/icons/enviroment-${activeEnvironment.type}.svg`}
                            className="environment-image"
                        />
                        <Text className="menu-dropdown-btn-text">{activeEnvironment.name}</Text>
                    </Box>
                }>
                {environmentMenuLinks}
            </MenuDropdown>
        );

        // INICIO
        const desktopMenuDropdown = (
            <MenuLink
                titleKey="menu.desktop"
                key="menu.desktop"
                linkTo="/desktop"
                image="images/icons/menu-home.svg"
                exact
                tabIndex="0"
            />
        );

        // PRODUCTOS
        let productsMenuDropdown = null;
        const productsMenuLinks = [];
        productsMenuLinks.push(
            <MenuLink titleKey="menu.products.myAccounts" key="menu.products.myAccounts" linkTo="/accounts" />,
        );

        productsMenuLinks.push(
            <MenuLink titleKey="menu.products.myCreditcards" key="menu.products.myCreditcards" linkTo="/creditCards" />,
        );

        productsMenuLinks.push(
            <MenuLink titleKey="menu.products.myLoans" key="menu.products.myLoans" linkTo="/loans" />,
        );

        productsMenuLinks.push(
            <MenuLink
                titleKey="menu.products.myCreditLines"
                key="menu.products.my.credit.lines"
                linkTo="/creditLines"
                exact
            />,
        );

        productsMenuLinks.push(
            <MenuLink titleKey="menu.deposits.myDeposits" key="menu.deposits.myDeposits" linkTo="/deposits" />,
        );

        // SINCRONIZACION
        productsMenuLinks.push(
            <Button
                label="menu.synchronization"
                bsStyle="menu-synchronization"
                key="menu.synchronization"
                onClick={this.syncEnvironmentProducts}
                loading={isfetchingSynchronization && synchProducts}
            />,
        );

        if (productsMenuLinks.length > 0) {
            productsMenuDropdown = (
                <MenuDropdown titleKey="menu.products" key="menu.products" image="images/icons/menu-products.svg">
                    {productsMenuLinks}
                </MenuDropdown>
            );
        }

        // TRANSFERENCIAS
        let transferMenuDropdown = null;
        const transferMenuLinks = [];
        // if (activeEnvironment.permissions.accounts) {
        if (activeEnvironment.permissions.transferInternal) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferInternal"
                    key="transferInternal"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        } else if (activeEnvironment.permissions.transferLocal || activeEnvironment.permissions.transferThirdParties) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferLocal"
                    key="transferLocal"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        } else if (activeEnvironment.permissions.transferForeign) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferForeign"
                    key="transferForeign"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        }
        // }

        if (transferMenuLinks.length <= 0) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferLocal"
                    key="transferLocal"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        }
        transferMenuLinks.push(
            <MenuLink
                linkTo="/InternationalTransferTracking"
                key="InternationalTransferTracking"
                titleKey="menu.transfers.tracking"
                exact
            />,
        );

        transferMenuDropdown = (
            <MenuDropdown
                id="menuTransfers"
                titleKey="menu.transfers"
                key="menu.transfers"
                image="images/icons/menu-transfers.svg">
                {transferMenuLinks}
            </MenuDropdown>
        );

        // Wally
        const wallyBTN = (
            <Button
                label="menu.wally"
                bsStyle="wally-menu"
                key="menu.wally"
                image="images/icons/menu-wally.svg"
                onClick={this.navigateToWally}
            />
        );

        const renderWallyBTN = () =>
            ((kuaraVersionNumber(1) && isLocalPhoneNumber()) || kuaraVersionNumber(2)) && (
                <FirstLevelItem>{wallyBTN}</FirstLevelItem>
            );

        // PAGOS
        let paymentMenuDropdown = null;
        const paymentMenuLinks = [];
        // if (activeEnvironment.permissions.accounts) {
        if (
            activeEnvironment.permissions.payCreditCard ||
            activeEnvironment.permissions.payCreditCardOther ||
            activeEnvironment.permissions.payCreditCardThird
        ) {
            if (activeEnvironment.permissions.payCreditCard) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.payments.creditCard"
                        key="menu.payments.creditCard"
                        linkTo="/formCustom/payCreditCardLocal"
                        exact
                    />,
                );
            } else if (activeEnvironment.permissions.payCreditCardThird) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.payments.creditCard"
                        key="menu.payments.creditCard"
                        linkTo="/formCustom/payCreditCardThird"
                        exact
                    />,
                );
            }
        } else {
            paymentMenuLinks.push(
                <MenuLink
                    titleKey="menu.payments.creditCard"
                    key="menu.payments.creditCard"
                    linkTo="/formCustom/payCreditCardLocal"
                    exact
                />,
            );
        }

        paymentMenuLinks.push(
            <MenuLink titleKey="menu.payments.loan" key="menu.payments.loan" linkTo="/formCustom/payLoan" exact />,
        );

        paymentMenuLinks.push(
            <MenuLink
                titleKey="menu.payments.servicePayment"
                key="menu.payments.servicePayment"
                linkTo="/servicePayments"
                exact
            />,
        );
        if (
            activeEnvironment.permissions.rechargeCreditCardLocal ||
            activeEnvironment.permissions.rechargeCreditCardThird
        ) {
            if (activeEnvironment.permissions.rechargeCreditCardLocal) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.reload.creditCard"
                        key="menu.reload.creditCard"
                        linkTo="/formCustom/rechargeCreditCardLocal"
                    />,
                );
            } else if (activeEnvironment.permissions.rechargeCreditCardThird) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.reload.creditCard"
                        key="menu.reload.creditCard"
                        linkTo="/formCustom/rechargeCreditCardThird"
                        exact
                    />,
                );
            }
        } else {
            paymentMenuLinks.push(
                <MenuLink
                    titleKey="menu.reload.creditCard"
                    key="menu.reload.creditCard"
                    linkTo="/formCustom/rechargeCreditCardLocal"
                />,
            );
        }
        if (!isMobileNativeFunc()) {
            if (activeEnvironment.permissions.rolePayment && activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.payment.payroll"
                        key="menu.payment.payroll"
                        linkTo="/salaryPayment/request"
                        exact
                    />,
                );
            }
            if (
                activeEnvironment.permissions.suppliersPayment &&
                activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE
            ) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.payment.suppliers"
                        key="menu.payment.suppliers"
                        linkTo="/suppliersPayment/request"
                        exact
                    />,
                );
            }
        }
        if (paymentMenuLinks.length > 0) {
            paymentMenuDropdown = (
                <MenuDropdown
                    id="menuPayment"
                    titleKey="menu.payments"
                    key="menu.payments"
                    image="images/icons/menu-payments.svg">
                    {paymentMenuLinks}
                </MenuDropdown>
            );
        }
        // }

        // TARJETAS DE DEBITO
        const debitCardMenuLink = (
            <MenuLink
                titleKey="menu.debitcard"
                key="menu.debitCard"
                linkTo="/debitCards"
                image="images/shortCutBtn/SCBcard.svg"
                exact
                tabIndex="0"
            />
        );

        // TODO MVP2: MIS FINANZAS
        const yourFinances = (
            <MenuLink
                titleKey="menu.yourFinances"
                key="menu.yourFinances"
                image="images/icons/menu-your-finances.svg"
                linkTo="/finances"
            />
        );

        // BENEFICIARY
        const beneficiaryMenuDropdown = (
            <MenuLink
                titleKey="menu.directory"
                key="menu.directory"
                linkTo="/frequentDestination"
                image="images/icons/menu-directory.svg"
                exact
                tabIndex="0"
            />
        );

        // HISTORIAL
        const transactionsMenuDropdown = (
            <MenuLink
                id="transactionHistory"
                titleKey="menu.transactions"
                key="menu.transactions"
                linkTo="/transactions/list"
                image="images/icons/menu-transactions.svg"
                exact
                tabIndex="0"
            />
        );

        // TRANSACCIONES PENDIENTES
        const pendingDispatch = (
            <FeatureFlag id="feature.signatureSchema.dispatchControl">
                <MenuLink
                    id="pendingOperations"
                    titleKey="menu.pendingDispatch"
                    key="menu.pendingDispatch"
                    linkTo="/pendingTransaction/list"
                    image="images/icons/menu-pending-transactions.svg"
                    notificationCount={pendingTransactionsQuantity}
                    notificationCountTextID="menu.pendingDispatch.badge"
                    exact
                    tabIndex="0"
                />
            </FeatureFlag>
        );

        // SOLICITUDES
        let requestsMenuDropdown = null;
        const requestsMenuLinks = [];

        // Solicitudes
        // Referencias bancarias
        requestsMenuLinks.push(
            <MenuLink
                titleKey="menu.request.bankReferences"
                key="menu.request.bankReferences"
                linkTo="/formCustom/referenceLetter"
            />,
        );

        // Solicitud productos
        requestsMenuLinks.push(
            <Button
                label="menu.request.productRequest"
                bsStyle="menu-synchronization"
                key="menu.request.productRequest"
                onClick={this.displayProductRequestModal}
            />,
        );
        requestsMenuLinks.push(
            <MenuLink
                titleKey="menu.RequestsAndComplaints"
                key="menu.RequestsAndComplaints"
                linkTo="/requestsAndComplaints"
            />,
        );
        if (activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE) {
            requestsMenuLinks.push(
                <MenuLink
                    titleKey="menu.checkbooksRequest"
                    key="menu.checkbooksRequest"
                    linkTo="/formCustom/requestsCheckBooks"
                />,
            );
        }

        // requestsMenuLinks.push(
        //     <MenuLink
        //         titleKey="menu.request.creditCardActivation"
        //         key="menu.request.creditCardActivation"
        //         linkTo="/websiteunderconstruction"
        //         exact
        //     />,
        // );

        requestsMenuDropdown = (
            <MenuDropdown titleKey="menu.request" key="menu.request" image="images/icons/menu-requests.svg">
                {requestsMenuLinks}
            </MenuDropdown>
        );

        // // SEGURIDAD
        // let securityMenuDropdown = null;
        // const securityMenuLinks = [];

        // // TODO: Activacion de Token
        // securityMenuLinks.push(
        //     <MenuLink
        //         titleKey="menu.security.tokenActivation"
        //         key="menu.security.tokenActivation"
        //         linkTo="/websiteunderconstruction"
        //     />,
        // );

        // if (securityMenuLinks.length > 0) {
        //     securityMenuDropdown = (
        //         <MenuDropdown titleKey="menu.security" key="menu.security" image="images/icons/menu-security.svg">
        //             {securityMenuLinks}
        //         </MenuDropdown>
        //     );
        // }

        // GESTION DE TARJETAS
        // const creditCardManagementLinks = null;
        // const creditCardManagement = (
        //     <MenuDropdown
        //         titleKey="menu.creditCardManagement"
        //         key="menu.creditCardManagement"
        //         image="images/icons/menu-credit-card-management.svg"
        //         linkTo="/pendingTransaction/list">
        //         {creditCardManagementLinks}
        //     </MenuDropdown>
        // );

        // ADMINISTRACIÓN (DESKTOP)
        const administrationMenuDropdown = this.buildAdministrationMenu();

        // PERFIL (MOBILE)
        const settingsMenuDropdown = (
            <MenuLink
                titleKey="menu.settings"
                key="menu.settings"
                linkTo="/settings"
                image="images/icons/menu-settings.svg"
                exact
            />
        );
        const transactionalProfileDropdown = <>{buttonTransactionalProfile}</>;

        // MENU-BG IMAGE
        const logo = (
            <Box component="li">
                <Box className="menu-img-bg">
                    <Image src="images/logos/menu-banesco-logo-bg.svg" />
                </Box>
            </Box>
        );

        const chatOption = (
            <Box>
                <MenuChat
                    titleKey="administration.menu.users"
                    key="menu.administration.users"
                    linkTo="/administration/users"
                />
            </Box>
        );
        return (
            <Nav className="nav-menu">
                {isMobile && <FirstLevelItem>{environmentMenuDropdown}</FirstLevelItem>}
                <FirstLevelItem>{desktopMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{productsMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{transferMenuDropdown}</FirstLevelItem>
                {/* Wally */}
                {isMobileNativeFunc() && activeEnvironment.type === "retail" && renderWallyBTN()}
                <FirstLevelItem>{paymentMenuDropdown}</FirstLevelItem>
                {activeEnvironment.type !== CORPORATE_ENVIRONMENT_TYPE && (
                    <FirstLevelItem>{debitCardMenuLink}</FirstLevelItem>
                )}
                <FirstLevelItem>{yourFinances}</FirstLevelItem>
                <FirstLevelItem>{beneficiaryMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{transactionsMenuDropdown}</FirstLevelItem>
                {activeEnvironment.type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                    <FirstLevelItem>{pendingDispatch}</FirstLevelItem>
                )}
                <FirstLevelItem>{requestsMenuDropdown}</FirstLevelItem>
                {/* <FirstLevelItem>{securityMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{creditCardManagement}</FirstLevelItem> */}
                <FirstLevelItem>{transactionalProfileDropdown}</FirstLevelItem>
                {activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE && (
                    <FirstLevelItem>{administrationMenuDropdown}</FirstLevelItem>
                )}
                {isMobile && <FirstLevelItem>{settingsMenuDropdown}</FirstLevelItem>}
                {!isMobileNative && activeEnvironment.type === "retail" && chatOption}
                {!isMobile && logo}
            </Nav>
        );
    }
}

Menu.propTypes = {
    isMobile: bool,
    isMobileNative: bool.isRequired,
    dispatch: func.isRequired,
    isAdministrator: bool,
    activeEnvironment: shape({
        permissions: shape({
            accounts: bool,
            deposits: bool,
            creditcards: bool,
            payCreditCard: bool,
            payCreditCardThird: bool,
            payCreditCardOther: bool,
            payLoan: bool,
            payThirdPartiesCreditCard: bool,
            requestTransactionCancellation: bool,
            settingsCorporateDataUpdate: bool,
            transferForeign: bool,
            transferInternal: bool,
            transferLocal: bool,
            transferThirdParties: bool,
            transferPeerToPeer: bool,
            requestCheckbook: bool,
        }),
        // eslint-disable-next-line react/forbid-prop-types
        forms: object,
        administrationScheme: string,
        type: string,
    }).isRequired,
    pendingTransactionsQuantity: number,
    isfetchingSynchronization: bool.isRequired,
    onOptionClicked: func,
    environments: shape().isRequired,
    fetchingTransactionalProfile: bool.isRequired,
    isFetchingBank: bool.isRequired,
};

Menu.defaultProps = {
    isMobile: false,
    isAdministrator: false,
    pendingTransactionsQuantity: 0,
    onOptionClicked: null,
};

function FirstLevelItem({ children }) {
    return <>{children}</>;
}

FirstLevelItem.propTypes = {
    children: node,
};
FirstLevelItem.defaultProps = {
    children: null,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lang: i18nSelectors.getLang(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
    pendingTransactionsQuantity: transactionsSelectors.getPendingTransactionsQuantity(state),
    loggedUser: sessionSelectors.getUser(state),
    isfetchingSynchronization: productSelectors.isfetchingSynchronization(state),
    environments: sessionSelectors.getEnvironments(state),
    fetchingTransactionalProfile: transactionalProfileSelectors.getFetching(state),
    isFetchingBank: formSelectors.isFetchingBank(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Menu)));
