import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    SHOW_UPDATE_USER_DATA_STEP_ZERO: "updateUserData/SHOW_UPDATE_USER_DATA_STEP_ZERO",
    HIDE_UPDATE_USER_DATA_STEP_ZERO: "updateUserData/HIDE_UPDATE_USER_DATA_STEP_ZERO",

    UPDATE_USER_DATA_PRE_REQUEST: "updateUserData/UPDATE_USER_DATA_PRE_REQUEST",
    UPDATE_USER_DATA_PRE_SUCCESS: "updateUserData/UPDATE_USER_DATA_PRE_SUCCESS",
    UPDATE_USER_DATA_PRE_FAILURE: "updateUserData/UPDATE_USER_DATA_PRE_FAILURE",

    GET_CREDENTIAL_GROUPS_REQUEST: "updateUserData/GET_CREDENTIAL_GROUPS_REQUEST",
    GET_CREDENTIAL_GROUPS_SUCCESS: "updateUserData/GET_CREDENTIAL_GROUPS_SUCCESS",
    GET_CREDENTIAL_GROUPS_FAILURE: "updateUserData/GET_CREDENTIAL_GROUPS_FAILURE",

    UPDATE_USER_DATA_STEP1_PRE_REQUEST: "updateUserData/UPDATE_USER_DATA_STEP1_PRE_REQUEST",
    UPDATE_USER_DATA_STEP1_PRE_SUCCESS: "updateUserData/UPDATE_USER_DATA_STEP1_PRE_SUCCESS",

    UPDATE_USER_DATA_STEP2_PRE_REQUEST: "updateUserData/UPDATE_USER_DATA_STEP2_PRE_REQUEST",
    UPDATE_USER_DATA_STEP2_PRE_SUCCESS: "updateUserData/UPDATE_USER_DATA_STEP2_PRE_SUCCESS",

    UPDATE_USER_DATA_STEP3_PRE_REQUEST: "updateUserData/UPDATE_USER_DATA_STEP3_PRE_REQUEST",
    UPDATE_USER_DATA_STEP3_PRE_SUCCESS: "updateUserData/UPDATE_USER_DATA_STEP3_PRE_SUCCESS",

    UPDATE_USER_DATA_STEP4_PRE_REQUEST: "updateUserData/UPDATE_USER_DATA_STEP4_PRE_REQUEST",
    UPDATE_USER_DATA_STEP4_PRE_SUCCESS: "updateUserData/UPDATE_USER_DATA_STEP4_PRE_SUCCESS",

    UPDATE_USER_DATA_STEP5_PRE_REQUEST: "updateUserData/UPDATE_USER_DATA_STEP5_PRE_REQUEST",
    UPDATE_USER_DATA_STEP5_PRE_SUCCESS: "updateUserData/UPDATE_USER_DATA_STEP5_PRE_SUCCESS",

    UPDATE_USER_DATA_STEP6_PRE_REQUEST: "updateUserData/UPDATE_USER_DATA_STEP6_PRE_REQUEST",
    UPDATE_USER_DATA_STEP6_PRE_SUCCESS: "updateUserData/UPDATE_USER_DATA_STEP6_PRE_SUCCESS",

    UPDATE_USER_DATA_FAILURE: "updateUserData/UPDATE_USER_DATA_FAILURE",

    UPDATE_USER_DATA_SAVE_FORM: "updateUserData/UPDATE_USER_DATA_SAVE_FORM",
    UPDATE_USER_DATA_SAVE_FORM_SUCCESS: "updateUserData/UPDATE_USER_DATA_SAVE_FORM_SUCCESS",
    UPDATE_USER_DATA_SAVE_FORM_FAILURE: "updateUserData/UPDATE_USER_DATA_SAVE_FORM_FAILURE",

    ENABLE_STEP_1: "updateUserData/ENABLE_STEP_1",
    ENABLE_STEP_2: "updateUserData/ENABLE_STEP_2",
    ENABLE_STEP_3: "updateUserData/ENABLE_STEP_3",
    ENABLE_STEP_4: "updateUserData/ENABLE_STEP_4",
    SKIP_STEP_6: "updateUserData/SKIP_STEP_6",

    GET_PROVINCE_LIST_REQUEST: "updateUserData/GET_PROVINCE_LIST_REQUEST",
    GET_PROVINCE_LIST_SUCCESS: "updateUserData/GET_PROVINCE_LIST_SUCCESS",
    GET_PROVINCE_LIST_FAILURE: "updateUserData/GET_PROVINCE_LIST_FAILURE",

    DISABLE_DISTRICT: "updateUserData/DISABLE_DISTRICT",
    GET_DISTRICT_LIST_REQUEST: "updateUserData/GET_DISTRICT_LIST_REQUEST",
    GET_DISTRICT_LIST_SUCCESS: "updateUserData/GET_DISTRICT_LIST_SUCCESS",
    GET_DISTRICT_LIST_FAILURE: "updateUserData/GET_DISTRICT_LIST_FAILURE",

    DISABLE_JURISDICTION: "updateUserData/DISABLE_JURISDICTION",
    GET_JURISDICTION_LIST_REQUEST: "updateUserData/GET_JURISDICTION_LIST_REQUEST",
    GET_JURISDICTION_LIST_SUCCESS: "updateUserData/GET_JURISDICTION_LIST_SUCCESS",
    GET_JURISDICTION_LIST_FAILURE: "updateUserData/GET_JURISDICTION_LIST_FAILURE",

    GET_STATE_PROVINCE_LIST_REQUEST: "updateUserData/GET_STATE_PROVINCE_LIST_REQUEST",
    GET_STATE_PROVINCE_LIST_SUCCESS: "updateUserData/GET_STATE_PROVINCE_LIST_SUCCESS",
    GET_STATE_PROVINCE_LIST_FAILURE: "updateUserData/GET_STATE_PROVINCE_LIST_FAILURE",

    DISABLE_CITY: "updateUserData/DISABLE_CITY",
    GET_CITY_LIST_REQUEST: "updateUserData/GET_CITY_LIST_REQUEST",
    GET_CITY_LIST_SUCCESS: "updateUserData/GET_CITY_LIST_SUCCESS",
    GET_CITY_LIST_FAILURE: "updateUserData/GET_CITY_LIST_FAILURE",

    SELECT_FILE: "updateUserData/SELECT_FILE",
    UPDATE_MANDATORY_ATTACHMENT: "updateUserData/UPDATE_MANDATORY_ATTACHMENT",
    UPDATE_ADDITIONAL_ATTACHMENT: "updateUserData/UPDATE_ADDITIONAL_ATTACHMENT",
    UPDATE_IDENTIFICATION_ATTACHMENT: "updateUserData/UPDATE_IDENTIFICATION_ATTACHMENT",
    REMOVE_MANDATORY_ATTACHMENT: "updateUserData/REMOVE_MANDATORY_ATTACHMENT",
    REMOVE_ADDITIONAL_ATTACHMENT: "updateUserData/REMOVE_ADDITIONAL_ATTACHMENT",
    REMOVE_IDENTIFICATION_ATTACHMENT: "updateUserData/REMOVE_IDENTIFICATION_ATTACHMENT",

    ENABLE_INCOME_FIELDS: "updateUserData/ENABLE_INCOME_FIELDS",

    VALIDATE_DOCUMENT_REQUEST: "updateUserData/VALIDATE_DOCUMENT_REQUEST",
    VALIDATE_DOCUMENT_SUCCESS: "updateUserData/VALIDATE_DOCUMENT_SUCCESS",
    VALIDATE_DOCUMENT_FAILURE: "updateUserData/VALIDATE_DOCUMENT_FAILURE",

    GO_TO_SETTINGS: "updateUserData/GO_TO_SETTINGS",
    VALIDATE_CLIENT_REMEDIATION: "updateUserData/VALIDATE_CLIENT_REMEDIATION",

    GET_UPDATE_INFORMATION: "updateUserData/GET_UPDATE_INFORMATION",
    HAS_UPDATES_PROCESSING: "updateUserData/HAS_UPDATES_PROCESSING",

    SET_COUNTRY_CODE_USER_DATA: "updateUserData/SET_COUNTRY_CODE_USER_DATA",
    VALIDATE_DOCUMENT_OCR: "updateUserData/VALIDATE_DOCUMENT_OCR",
};

export const INITIAL_STATE = {
    exchangeToken: undefined,
    currentUserData: undefined,
    fetching: false,
    credentialGroups: undefined,
    showUpdateUserDataStepZero: false,
    preDataStep1: {},
    preDataStep2: {},
    preDataStep3: {},
    preDataStep4: {},
    preDataStep5: {},
    preDataStep6: {},
    isDisabledStep1: true,
    isDisabledStep2: true,
    isDisabledStep3: true,
    isDisabledStep4: true,
    skipStep6: false,
    submitting: false,
    provinceList: {},
    isDisabledDistrict: true,
    districtList: {},
    districtByProvinceList: {},
    isDisabledJurisdiction: true,
    jurisdictionList: {},
    isDisabledCity: true,
    cityListRequest: {},
    selectedMandatoryAttachments: [],
    selectedAdditionalAttachments: [],
    selectedIdentificationAttachments: [],
    mandatoryCount: 0,
    additionalCount: 0,
    identificationCount: 0,
    isDisabledIncomeFields: true,
    identityDocument: undefined,
    isValidDocument: false,
    loading: false,
    isNeedingUpdate: false,
    lastUpdDt: undefined,
    hasUpdatesProcessing: false,
    fetchingProvince: false,
    fetchingDistrict: false,
    fetchingJuridistion: false,
    fetchingCity: false,
    fetchingData: false,
    countryCodeUserData: "",
    documentValidationOcr: true,
};

export const actions = {
    getCredentialGroupsRequest: makeActionCreator(types.GET_CREDENTIAL_GROUPS_REQUEST, "credential"),
    updateUserDataRequest: makeActionCreator(types.UPDATE_USER_DATA_PRE_REQUEST, "otp", "formikBag"),
    showUpdateUserDataStepZero: makeActionCreator(types.SHOW_UPDATE_USER_DATA_STEP_ZERO),
    hideUpdateUserDataStepZero: makeActionCreator(types.HIDE_UPDATE_USER_DATA_STEP_ZERO),
    preFormStep1: makeActionCreator(types.UPDATE_USER_DATA_STEP1_PRE_REQUEST, "exchangeToken"),
    preFormStep1Success: makeActionCreator(types.UPDATE_USER_DATA_STEP1_PRE_SUCCESS, "preDataStep1"),
    preFormStep2: makeActionCreator(types.UPDATE_USER_DATA_STEP2_PRE_REQUEST, "exchangeToken"),
    preFormStep2Success: makeActionCreator(types.UPDATE_USER_DATA_STEP2_PRE_SUCCESS, "preDataStep2"),
    preFormStep3: makeActionCreator(types.UPDATE_USER_DATA_STEP3_PRE_REQUEST, "exchangeToken"),
    preFormStep3Success: makeActionCreator(types.UPDATE_USER_DATA_STEP3_PRE_SUCCESS, "preDataStep3"),
    preFormStep4: makeActionCreator(types.UPDATE_USER_DATA_STEP4_PRE_REQUEST, "exchangeToken"),
    preFormStep4Success: makeActionCreator(types.UPDATE_USER_DATA_STEP4_PRE_SUCCESS, "preDataStep4"),
    preFormStep5: makeActionCreator(types.UPDATE_USER_DATA_STEP5_PRE_REQUEST, "exchangeToken"),
    preFormStep5Success: makeActionCreator(types.UPDATE_USER_DATA_STEP5_PRE_SUCCESS, "preDataStep5"),
    preFormStep6: makeActionCreator(types.UPDATE_USER_DATA_STEP6_PRE_REQUEST, "exchangeToken"),
    preFormStep6Success: makeActionCreator(
        types.UPDATE_USER_DATA_STEP6_PRE_SUCCESS,
        "preDataStep6",
        "identityDocument",
    ),
    requestFailure: makeActionCreator(types.UPDATE_USER_DATA_FAILURE),
    enableStep1: makeActionCreator(types.ENABLE_STEP_1, "isDisabledStep1"),
    enableStep2: makeActionCreator(types.ENABLE_STEP_2, "isDisabledStep2"),
    enableStep3: makeActionCreator(types.ENABLE_STEP_3, "isDisabledStep3"),
    enableStep4: makeActionCreator(types.ENABLE_STEP_4, "isDisabledStep4"),
    skippingStep6: makeActionCreator(types.SKIP_STEP_6, "skipStep6"),
    saveForm: makeActionCreator(types.UPDATE_USER_DATA_SAVE_FORM, "values", "step", "formikBag"),
    saveFormSuccess: makeActionCreator(types.UPDATE_USER_DATA_SAVE_FORM_SUCCESS),
    saveFormFailure: makeActionCreator(types.UPDATE_USER_DATA_PRE_FAILURE),

    getProvinceListRequest: makeActionCreator(types.GET_PROVINCE_LIST_REQUEST, "countryCode"),
    getProvinceListSuccess: makeActionCreator(types.GET_PROVINCE_LIST_SUCCESS, "provinceList"),
    getProvinceListFailure: makeActionCreator(types.GET_PROVINCE_LIST_FAILURE),

    disableDistrict: makeActionCreator(types.DISABLE_DISTRICT, "disableDistrict"),
    getDistrictListRequest: makeActionCreator(types.GET_DISTRICT_LIST_REQUEST, "provinceCode"),
    getDistrictListSuccess: makeActionCreator(types.GET_DISTRICT_LIST_SUCCESS, "districtByProvinceList"),
    getDistrictListFailure: makeActionCreator(types.GET_DISTRICT_LIST_FAILURE),

    disableJurisdiction: makeActionCreator(types.DISABLE_JURISDICTION, "disableJurisdiction"),
    getJurisdictionListRequest: makeActionCreator(types.GET_JURISDICTION_LIST_REQUEST, "districtCode"),
    getJurisdictionListSuccess: makeActionCreator(types.GET_JURISDICTION_LIST_SUCCESS, "jurisdictionList"),
    getJurisdictionListFailure: makeActionCreator(types.GET_JURISDICTION_LIST_FAILURE),

    disableCity: makeActionCreator(types.DISABLE_CITY, "disableCity"),
    getCityListRequest: makeActionCreator(types.GET_CITY_LIST_REQUEST, "stateProvinceCode"),
    getCityListSuccess: makeActionCreator(types.GET_CITY_LIST_SUCCESS, "cityByStateProvinceList"),
    getCityListFailure: makeActionCreator(types.GET_CITY_LIST_FAILURE),

    selectFile: makeActionCreator(
        types.SELECT_FILE,
        "file",
        "isMandatory",
        "internalIndex",
        "labelKey",
        "identityDocument",
        "isIdentification",
    ),
    updateMandatoryAttachments: makeActionCreator(types.UPDATE_MANDATORY_ATTACHMENT, "files"),
    updateAdditionalAttachments: makeActionCreator(types.UPDATE_ADDITIONAL_ATTACHMENT, "files"),
    updateIdentificationAttachments: makeActionCreator(types.UPDATE_IDENTIFICATION_ATTACHMENT, "files"),
    removeMandatoryAttachments: makeActionCreator(types.REMOVE_MANDATORY_ATTACHMENT, "files"),
    removeAdditionalAttachments: makeActionCreator(types.REMOVE_ADDITIONAL_ATTACHMENT, "files"),
    removeIdentificationAttachments: makeActionCreator(types.REMOVE_IDENTIFICATION_ATTACHMENT, "files"),
    enableIncomeFields: makeActionCreator(types.ENABLE_INCOME_FIELDS, "isDisabledIncomeFields"),

    validateDocumentRequest: makeActionCreator(types.VALIDATE_DOCUMENT_REQUEST),
    validateDocumentSuccess: makeActionCreator(
        types.VALIDATE_DOCUMENT_SUCCESS,
        "isValidDocument",
        "documentValidationOcr",
    ),
    validateDocumentFailure: makeActionCreator(types.VALIDATE_DOCUMENT_FAILURE),

    goToSettingsFromModal: makeActionCreator(types.GO_TO_SETTINGS),
    validateClientRemediation: makeActionCreator(types.VALIDATE_CLIENT_REMEDIATION),

    getUpdateInformation: makeActionCreator(
        types.GET_UPDATE_INFORMATION,
        "hasUpdatesProcessing",
        "isNeedingUpdate",
        "lastUpdDt",
    ),
    hasUpdatesProcessing: makeActionCreator(types.HAS_UPDATES_PROCESSING, "hasUpdatesProcessing"),
    setCountryCodeUserData: makeActionCreator(types.SET_COUNTRY_CODE_USER_DATA, "countryCodeUserData"),
    setDocumentValidationOcr: makeActionCreator(types.VALIDATE_DOCUMENT_OCR, "documentValidationOcr"),
};

export const selectors = {
    isFetching: ({ updateUserData }) => updateUserData?.fetching,
    getCredentialGroups: ({ updateUserData }) => updateUserData?.credentialGroups || [],
    isShowUpdateUserDataStepZero: ({ updateUserData }) => updateUserData?.showUpdateUserDataStepZero,
    getExchangeToken: ({ updateUserData }) => updateUserData?.exchangeToken,
    getCurrentUserData: ({ updateUserData }) => updateUserData?.currentUserData,
    getPreDataStep1: ({ updateUserData }) => updateUserData?.preDataStep1,
    getPreDataStep2: ({ updateUserData }) => updateUserData?.preDataStep2,
    getPreDataStep3: ({ updateUserData }) => updateUserData?.preDataStep3,
    getPreDataStep4: ({ updateUserData }) => updateUserData?.preDataStep4,
    getPreDataStep5: ({ updateUserData }) => updateUserData?.preDataStep5,
    getPreDataStep6: ({ updateUserData }) => updateUserData?.preDataStep6,
    isDisabledStep1: ({ updateUserData }) => updateUserData?.isDisabledStep1,
    isDisabledStep2: ({ updateUserData }) => updateUserData?.isDisabledStep2,
    isDisabledStep3: ({ updateUserData }) => updateUserData?.isDisabledStep3,
    isDisabledStep4: ({ updateUserData }) => updateUserData?.isDisabledStep4,
    isSkippingStep6: ({ updateUserData }) => updateUserData?.skipStep6,
    isSubmitting: ({ updateUserData }) => updateUserData?.submitting,
    isNeedingUpdate: ({ updateUserData }) => updateUserData?.isNeedingUpdate,
    getLastUpdateDate: ({ updateUserData }) => updateUserData?.lastUpdDt,
    hasUpdatesProcessing: ({ updateUserData }) => updateUserData?.hasUpdatesProcessing,

    isDisabledDistrict: ({ updateUserData }) => updateUserData?.isDisabledDistrict,
    getDistrictByProvinceList: ({ updateUserData }) => updateUserData?.districtByProvinceList,

    isDisabledJurisdiction: ({ updateUserData }) => updateUserData?.isDisabledJurisdiction,
    getJurisdictionList: ({ updateUserData }) => updateUserData?.jurisdictionList,

    getProvinceList: ({ updateUserData }) => updateUserData?.provinceList,

    isDisabledCity: ({ updateUserData }) => updateUserData?.isDisabledCity,
    getCityList: ({ updateUserData }) => updateUserData?.cityByStateProvinceList,

    getSelectedMandatoryAttachments: ({ updateUserData }) => updateUserData?.selectedMandatoryAttachments,
    getSelectedAdditionalAttachments: ({ updateUserData }) => updateUserData?.selectedAdditionalAttachments,
    getSelectedIdentificationAttachments: ({ updateUserData }) => updateUserData?.selectedIdentificationAttachments,

    getMandatoryCount: ({ updateUserData }) => updateUserData?.mandatoryCount,
    getAdditionalCount: ({ updateUserData }) => updateUserData?.additionalCount,
    getIdentificationCount: ({ updateUserData }) => updateUserData?.identificationCount,
    isDisabledIncomeFields: ({ updateUserData }) => updateUserData?.isDisabledIncomeFields,

    getIdentityDocument: ({ updateUserData }) => updateUserData?.identityDocument,
    isValidDocument: ({ updateUserData }) => updateUserData?.isValidDocument,
    isLoading: ({ updateUserData }) => updateUserData?.loading,
    isFetchingProvince: ({ updateUserData }) => updateUserData?.fetchingProvince,
    isFetchingDistrict: ({ updateUserData }) => updateUserData?.fetchingDistrict,
    isFetchingJuridistion: ({ updateUserData }) => updateUserData?.fetchingJuridistion,
    isFetchingCity: ({ updateUserData }) => updateUserData?.fetchingCity,
    isFetchingData: ({ updateUserData }) => updateUserData?.fetchingData,
    getCountryCodeUserData: ({ updateUserData }) => updateUserData?.countryCodeUserData,
    getDocumentValidationOcr: ({ updateUserData }) => updateUserData?.documentValidationOcr,
};

export default createReducer(INITIAL_STATE, {
    [types.SHOW_UPDATE_USER_DATA_STEP_ZERO]: (state) => ({
        ...state,
        showUpdateUserDataStepZero: true,
        fetchingData: true,
    }),
    [types.HIDE_UPDATE_USER_DATA_STEP_ZERO]: (state) => ({
        ...state,
        showUpdateUserDataStepZero: false,
    }),
    [types.UPDATE_USER_DATA_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        isDisabledStep1: true,
        isDisabledStep2: true,
        isDisabledStep3: true,
        isDisabledStep4: true,
        isDisabledIncomeFields: true,
    }),
    [types.UPDATE_USER_DATA_PRE_SUCCESS]: (state, action) => ({
        ...state,
        // Se deja el fetching en true por la carga de la página ya que sino hace un flash en la carga en web y mobile
        // (luego se hace otro llamado al step1 que setea en false)
        fetching: true,
        currentUserData: action?.data?.currentUserData,
        exchangeToken: action?.data?.exchangeToken,
        showUpdateUserDataStepZero: false,
    }),
    [types.UPDATE_USER_DATA_PRE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        fetchingData: false,
        submitting: false,
        currentUserData: undefined,
        showUpdateUserDataStepZero: false,
    }),
    [types.GET_CREDENTIAL_GROUPS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_CREDENTIAL_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        credentialGroups: action?.credentialsGroups,
    }),
    [types.GET_CREDENTIAL_GROUPS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        credentialGroups: undefined,
    }),
    [types.UPDATE_USER_DATA_STEP1_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_USER_DATA_STEP1_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep1: action.preDataStep1,
        selectedMandatoryAttachments: [],
        mandatoryCount: 0,
        selectedAdditionalAttachments: [],
        additionalCount: 0,
        selectedIdentificationAttachments: [],
        identificationCount: 0,
    }),
    [types.UPDATE_USER_DATA_STEP2_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_USER_DATA_STEP2_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep2: action.preDataStep2,
    }),
    [types.UPDATE_USER_DATA_STEP3_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_USER_DATA_STEP3_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep3: action.preDataStep3,
    }),
    [types.UPDATE_USER_DATA_STEP4_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_USER_DATA_STEP4_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep4: action.preDataStep4,
    }),
    [types.UPDATE_USER_DATA_STEP5_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_USER_DATA_STEP5_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep5: action.preDataStep5,
    }),
    [types.UPDATE_USER_DATA_STEP6_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_USER_DATA_STEP6_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep6: action.preDataStep6,
        identityDocument: action.identityDocument,
    }),
    [types.UPDATE_USER_DATA_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        submitting: false,
        isDisabledStep1: true,
        isDisabledStep2: true,
        isDisabledStep3: true,
        isDisabledStep4: true,
        isDisabledIncomeFields: true,
    }),
    [types.ENABLE_STEP_1]: (state, action) => ({
        ...state,
        isDisabledStep1: action.isDisabledStep1,
    }),
    [types.ENABLE_STEP_2]: (state, action) => ({
        ...state,
        isDisabledStep2: action.isDisabledStep2,
        isDisabledDistrict: action.isDisabledStep2,
        isDisabledJurisdiction: action.isDisabledStep2,
        isDisabledCity: action.isDisabledStep2,
    }),
    [types.ENABLE_STEP_3]: (state, action) => ({
        ...state,
        isDisabledStep3: action.isDisabledStep3,
        isDisabledDistrict: action.isDisabledStep3,
        isDisabledJurisdiction: action.isDisabledStep3,
        isDisabledCity: action.isDisabledStep3,
    }),
    [types.ENABLE_STEP_4]: (state, action) => ({
        ...state,
        isDisabledStep4: action.isDisabledStep4,
    }),
    [types.SKIP_STEP_6]: (state) => ({
        ...state,
        skipStep6: true,
    }),
    [types.UPDATE_USER_DATA_SAVE_FORM]: (state) => ({
        ...state,
        submitting: true,
    }),
    [types.UPDATE_USER_DATA_SAVE_FORM_SUCCESS]: (state) => ({
        ...state,
        submitting: false,
        fetching: false,
    }),
    [types.UPDATE_USER_DATA_SAVE_FORM_FAILURE]: (state) => ({
        ...state,
        submitting: false,
    }),
    [types.GET_PROVINCE_LIST_REQUEST]: (state) => ({
        ...state,
        fetchingProvince: true,
    }),
    [types.GET_PROVINCE_LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetchingProvince: false,
        provinceList: action.provinceList,
    }),
    [types.GET_PROVINCE_LIST_FAILURE]: (state) => ({
        ...state,
        fetchingProvince: false,
    }),
    [types.DISABLE_DISTRICT]: (state, action) => ({
        ...state,
        isDisabledDistrict: action.disableDistrict,
    }),
    [types.GET_DISTRICT_LIST_REQUEST]: (state) => ({
        ...state,
        fetchingDistrict: true,
    }),
    [types.GET_DISTRICT_LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetchingDistrict: false,
        districtByProvinceList: action.districtByProvinceList,
    }),
    [types.GET_DISTRICT_LIST_FAILURE]: (state) => ({
        ...state,
        fetchingDistrict: false,
    }),
    [types.DISABLE_JURISDICTION]: (state, action) => ({
        ...state,
        isDisabledJurisdiction: action.disableJurisdiction,
    }),
    [types.GET_JURISDICTION_LIST_REQUEST]: (state) => ({
        ...state,
        fetchingJuridistion: true,
    }),
    [types.GET_JURISDICTION_LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetchingJuridistion: false,
        jurisdictionList: action.jurisdictionList,
    }),
    [types.GET_JURISDICTION_LIST_FAILURE]: (state) => ({
        ...state,
        fetchingJuridistion: false,
    }),
    [types.DISABLE_CITY]: (state, action) => ({
        ...state,
        // fetching: true,
        isDisabledCity: action.disableCity,
    }),
    [types.GET_CITY_LIST_REQUEST]: (state) => ({
        ...state,
        fetchingCity: true,
    }),
    [types.GET_CITY_LIST_SUCCESS]: (state, action) => ({
        ...state,
        cityByStateProvinceList: action.cityByStateProvinceList,
        fetchingCity: false,
    }),
    [types.GET_CITY_LIST_FAILURE]: (state) => ({
        ...state,
        fetchingCity: false,
    }),
    [types.SELECT_FILE]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.UPDATE_MANDATORY_ATTACHMENT]: (state, action) => ({
        ...state,
        loading: false,
        selectedMandatoryAttachments: action.files,
        mandatoryCount: state.mandatoryCount + 1,
    }),
    [types.UPDATE_ADDITIONAL_ATTACHMENT]: (state, action) => ({
        ...state,
        loading: false,
        selectedAdditionalAttachments: action.files,
        additionalCount: state.additionalCount + 1,
    }),
    [types.UPDATE_IDENTIFICATION_ATTACHMENT]: (state, action) => ({
        ...state,
        loading: false,
        selectedIdentificationAttachments: action.files,
        identificationCount: state.identificationCount + 1,
    }),
    [types.REMOVE_MANDATORY_ATTACHMENT]: (state, action) => ({
        ...state,
        loading: false,
        selectedMandatoryAttachments: action.files,
        mandatoryCount: state.mandatoryCount - 1,
    }),
    [types.REMOVE_ADDITIONAL_ATTACHMENT]: (state, action) => ({
        ...state,
        loading: false,
        selectedAdditionalAttachments: action.files,
        additionalCount: state.additionalCount - 1,
    }),
    [types.REMOVE_IDENTIFICATION_ATTACHMENT]: (state, action) => ({
        ...state,
        loading: false,
        selectedIdentificationAttachments: action.files,
        identificationCount: state.mandatoryCount - 1,
    }),
    [types.ENABLE_INCOME_FIELDS]: (state, action) => ({
        ...state,
        isDisabledIncomeFields: action.isDisabledIncomeFields,
    }),
    [types.VALIDATE_DOCUMENT_REQUEST]: (state) => ({
        ...state,
        submitting: true,
    }),
    [types.VALIDATE_DOCUMENT_SUCCESS]: (state, action) => ({
        ...state,
        isValidDocument: action.isValidDocument,
        documentValidationOcr: action.documentValidationOcr,
        submitting: false,
    }),
    [types.VALIDATE_DOCUMENT_FAILURE]: (state) => ({
        ...state,
        isValidDocument: false,
        submitting: false,
    }),
    [types.GET_UPDATE_INFORMATION]: (state, action) => ({
        ...state,
        fetchingData: false,
        hasUpdatesProcessing: action.hasUpdatesProcessing,
        isNeedingUpdate: action.isNeedingUpdate,
        lastUpdDt: action.lastUpdDt,
    }),
    [types.HAS_UPDATES_PROCESSING]: (state, action) => ({
        ...state,
        hasUpdatesProcessing: action.hasUpdatesProcessing,
    }),
    [types.SET_COUNTRY_CODE_USER_DATA]: (state, action) => ({
        ...state,
        countryCodeUserData: action.countryCodeUserData,
    }),
    [types.VALIDATE_DOCUMENT_OCR]: (state, action) => ({
        ...state,
        documentValidationOcr: action.documentValidationOcr,
        submitting: false,
    }),
});
